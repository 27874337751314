<template>
  <div id="stageline-graphic-component">
    <template v-if="hasStageList">
      <div class="stageline-graphic current">
        <template v-if="showPreviousStageline">
          <div v-if="previousStageline.stagelineType !== 'business_info'"
               class="stageline-graphic previous stageline-controls"
          >
            <div class="vstack"
                 @click="goToStageline('business_info')"
            >
              <div class="indicator-flex" />
              <div class="indicator">
                <div class="title secondary">
                  Update Business Info
                </div>
                <stage-complete-icon v-if="stagelineComplete('business_info')" />
              </div>
              <div class="indicator-flex" />
            </div>
            <div class="ellipse-icon">
              <ellipse-icon />
            </div>
          </div>

          <div class="stageline-graphic previous stageline-controls">
            <div class="vstack"
                 @click="goToStageline(previousStageline.stagelineType)"
            >
              <div class="indicator-flex" />
              <div class="indicator">
                <div class="title secondary">
                  {{ previousStageline.title }}
                </div>
                <stage-complete-icon v-if="stagelineComplete(previousStageline.stagelineType)" />
              </div>
              <div class="indicator-flex" />
            </div>
            <div class="ellipse-icon">
              <ellipse-icon />
            </div>
          </div>
        </template>

        <div v-if="previousPageAvailable"
             class="nav-arrow-container"
             @click="goPageBack"
        >
          <nav-arrow class="nav-arrow left" />
        </div>

        <div v-for="(stage, i) in stageList"
             v-if="!dummy && currentStage"
             :key="stage.id"
             :class="`stage ${stage.id === currentStage.id ? 'active' : ''} ${i === stageList.length -1 ? 'last-stage' : ''} ${completed(stage) && stage.id !== currentStage.id ? 'complete' : ''}`"
             @click="clicked(stage)"
        >
          <div :class="`indicator ${completed(stage) && stage.id !== currentStage.id ? 'active' : ''}`">
            <div :class="`title ${completed(stage) || stage.id === currentStage.id ? 'complete-title' : ''}`">
              <div>
                {{ stage.title }}
              </div>
            </div>

            <stage-current-icon v-if="stage.id === currentStage.id"
                                class="current-indicator"
            />
            <stage-complete-icon v-if="completed(stage) && stage.id !== currentStage.id" />
          </div>
          <div v-if="i !== stageList.length -1"
               class="progress-line"
          >
            <hr>
          </div>
        </div>

        <div
          v-if="dummy"
          class="stage active last-stage"
        >
          <div class="indicator active">
            <div class="title complete-title">
              <div>What's Next?</div>
            </div>
            <stage-current-icon />
          </div>
        </div>

        <div v-if="nextPageAvailable"
             class="nav-arrow-container"
             @click="goPageForward"
        >
          <nav-arrow class="nav-arrow right" />
        </div>

        <div v-if="!dummy && (currentStage && showNextStageline)"
             class="stageline-graphic next stageline-controls"
        >
          <div class="ellipse-icon">
            <ellipse-icon />
          </div>
          <div class="vstack"
               @click="goToNextStageline"
          >
            <div class="indicator-flex" />
            <div class="indicator">
              <div class="title secondary">
                {{ nextStageline.title }}
              </div>
              <stage-complete-icon v-if="stagelineComplete(nextStageline.stagelineType)" />
            </div>
            <div class="indicator-flex" />
          </div>
        </div>

        <div v-if="dummy"
             class="stageline-graphic next stageline-controls"
        >
          <div class="ellipse-icon">
            <ellipse-icon />
          </div>
          <div class="vstack">
            <div class="indicator-flex" />
            <div class="indicator">
              <div class="title secondary" />
              <div class="dummy-text">
                ?
              </div>
            </div>
            <div class="indicator-flex" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StageCurrentIcon from '@images/ui/step-current.svg'
import StageCompleteIcon from '@images/ui/step-complete.svg'
import EllipseIcon from '@images/ui/ellipse-icon.svg'
import NavArrow from '@images/ui/nav-arrow.svg'

export default {
  name: 'StagelineGraphic',
  components: {
    StageCurrentIcon,
    StageCompleteIcon,
    EllipseIcon,
    NavArrow,
  },
  props: {
    stagelineType: {
      type: String,
      default: '',
    },
    dummy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      stagesPerPage: 4,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'currentStage',
      'currentStageIndex',
      'filteredStages',
      'stagelineLoaded',
      'stagelineComplete',
      'showFormationStageline',
    ]),
    ...mapGetters('companies', ['formedElsewhere']),
    previousPageAvailable() {
      return this.currentPage !== 1
    },
    nextPageAvailable() {
      return this.stagelineLoaded && this.filteredStages.length > this.currentPage * this.stagesPerPage
    },
    previousStageline() {
      switch (this.stagelineType) {
        case 'business_info':
          return null
        case 'form':
          return { title: 'Update Business Info', stagelineType: 'business_info' }
        default:
          if (this.showFormationStageline) {
            return { title: 'Form Your Company', stagelineType: 'form' }
          } else {
            return { title: 'Update Business Info', stagelineType: 'business_info' }
          }
      }
    },
    nextStageline() {
      switch (this.stagelineType) {
        case 'business_info':
          if (this.formedElsewhere || this.stagelineComplete('form')) {
            // Would go to decision page, but we want to prevent premature navigation there.
            return { title: "What's Next?", stagelineType: '' }
          } else {
            return { title: 'Form Your Company', stagelineType: 'form' }
          }
        case 'form':
          return { title: 'Maintain Your Company', stagelineType: 'maintain' }
        default:
          return { title: "What's Next?", stagelineType: '' }
      }
    },
    showPreviousStageline() {
      return !!this.previousStageline
    },
    showNextStageline() {
      return !!this.nextStageline
    },
    stageList(){
      return this.filteredStages
        .slice((this.currentPage - 1) * this.stagesPerPage, this.currentPage * this.stagesPerPage)
    },
    hasStageList() {
      return (this.stageList && this.stageList.length > 0) || this.dummy
    },
  },
  watch: {
    currentStage() {
      this.calculatePage()
    },
  },
  mounted() {
    this.calculatePage()
  },
  methods: {
    ...mapActions('stageline', [
      'setCurrentStage',
      'setCurrentStep',
    ]),
    completed(stage) {
      return stage && stage.company_stage.completed_at !== null
    },
    nextStageCompleted(stage) {
      const index = this.filteredStages.indexOf(stage)

      if (index === this.filteredStages.length - 1) {
        return this.completed(this.filteredStages[index])
      }

      return this.completed(this.filteredStages[index + 1])
    },
    async clicked(stage) {
      await this.setCurrentStage(this.filteredStages.findIndex(s => s.id === stage.id))
    },
    goPageBack() {
      this.currentPage--
    },
    goPageForward() {
      this.currentPage++
    },
    goToStageline(stagelineType) {
      this.$router.push({
        name: 'stageline',
        params: {
          companyId: this.company.id,
          stagelineType: stagelineType,
        },
      })
    },
    goToNextStageline() {
      if (this.nextStageline.stagelineType === ''
        && this.stagelineType === 'business_info'
        && !this.stagelineComplete('business_info')
      ) { return }

      this.$router.push({
        name: 'stageline',
        params: {
          companyId: this.company.id,
          stagelineType: this.nextStageline.stagelineType,
        },
      })
    },
    calculatePage() {
      this.currentPage = parseInt(this.currentStageIndex / this.stagesPerPage) + 1
    },
  },
}
</script>

<style lang="scss" scoped>
  .stage.last-stage {
    flex: unset !important;
  }

  .outer-container {
  }

  .icon {
    color: $icon-color;
  }

  .icon-dark {
    color: #494949;
  }

  .help-icon {
    position: absolute;
    margin-left: 0.25em;
    margin-top: -0.25em;
    font-size: 75%;
    visibility: hidden;
  }

  .transparent {
    background-color: transparent !important;
    border-color: transparent !important;
    color: transparent !important;
  }

  #stageline-graphic-component {
    width: 90%;
    margin: 4em 6em 3em 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;

    button:focus {
      outline: 0;
    }

    .current-indicator {
    }

    .nav-arrow-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .nav-arrow {
        height: 40px;
        cursor: pointer;

        &.left {
          transform: rotate(180deg);
        }
      }
    }

    .vstack {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100%;
      max-width: 200px;
    }

    .progress-line {
      justify-content: center;
      min-height: 100%;
      min-width: 60px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      hr {
        border-color: $ct-ui-primary !important;
        width: 70% !important;
      }

      &.hidden {
        width: 0;
      }
    }

    .justify-center {
      flex: 3;
      justify-content: center;
    }

    .indicator-padding {
      height: $ct-ui-stageline-graphic-linewidth ;
      background-color: $ct-ui-font-color-secondary;
    }

    .title {
      text-transform: capitalize;
      font-weight: bold;
      font-size: .8rem;
      max-width: 100px;
      color: $ct-ui-secondary;
      position: absolute;
      margin-left: -100%; /* Cool hack */
      margin-right: -100%;
      margin-bottom: 118px;
      text-align: center;
      white-space: normal;

      &.secondary {
        color: $ct-ui-font-color-secondary;
      }
    }

    .complete-title {
      color: $ct-ui-secondary;
    }

    .indicator-margin {
      height: 20px;
      margin-right: 1.25em;
      margin-left: 1.25em;
      background-color: transparent;
    }

    .indicator-flex {
      min-height: 10px;
      width: 15px;
      background-color: transparent;
    }
  }

  .stageline-graphic {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;


    .indicator {
      position: relative;
      background-color: transparent;
      border-radius: 50%;
      border: $ct-ui-stageline-graphic-linewidth  solid $ct-ui-font-color-secondary;
      width: 50px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.current {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: inherit;

      .stage {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        white-space: nowrap;
        text-align: center;
        position: relative;
        flex: 0 1 200px;

        :hover {

          .help-icon {
            visibility: visible !important;
          }
        }

        .indicator {
          min-width: 50px;
        }

        &.active .indicator {
          background-color: $ct-ui-primary-light;
          border: 1px solid $ct-ui-primary;
          border-radius: 50%;
        }

        &.complete .indicator {
          background-color: $ct-ui-primary;
          border: 1px solid $ct-ui-primary;
          border-radius: 50%;
        }
      }
    }

    &.previous {
      width: 130px !important;
      justify-content: space-between !important;
      margin-right: 26px !important;

      .indicator {
        border-color: $ct-ui-color-2;
        background-color: $ct-ui-color-2;
        margin-right: 25px;
      }
    }
    &.next {
      width: 130px !important;
      justify-content: space-between !important;
      margin-left: 26px !important;
      border-color: $ct-ui-color-2;

      .indicator {
        margin-left: 25px;
      }
    }

    &.stageline-controls {
      justify-content: space-evenly;
      width: 180px;

      .title {
        letter-spacing: 0;
        margin-bottom: 118px !important;
        text-transform: capitalize !important;
        line-height: initial;
      }

      .indicator {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }

    .ellipse-icon {
      width: 50px;
      position: relative;
      bottom: 2px;
      svg {
        width: 100%;
      }
    }
  }

  .dummy-text {
    color: $ct-ui-font-color-secondary;
  }

  @media only screen and (max-width: 1024px) {
    #stageline-graphic-component {
      width: 100% !important;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin: 3.0em 0 1.0em 1.0em !important;
    }

    .stageline-graphic {

      &.previous {
        width: 75px !important;
        margin-right: 1.625em !important;
      }

      &.next {
        width: 75px !important;

        .indicator {
          margin-left: 0 !important;
        }
      }

      .stage {
        max-width: 150px;
      }

      .title {
        font-size: 0.7rem !important;
        margin-bottom: 80px !important;
      }

      .indicator {
        width: 30px;
        min-width: 30px !important;
        height: 30px;
      }

      &.stageline-controls {
        width: 140px;

        .title {
          margin-bottom: 80px !important;
          line-height: 13px !important;
        }

        .indicator {
          width: 25px;
          min-width: 25px !important;
          height: 25px;
        }
      }

      .ellipse-icon {
        width: 25px;
      }
    }
  }

  @media only screen and (max-width: 660px) {
    .stageline-controls {
      display: none !important;
    }

    #stageline-graphic-component {
      width: 85% !important;
    }
  }

</style>
