var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline-graphic-component" } },
    [
      _vm.hasStageList
        ? [
            _c(
              "div",
              { staticClass: "stageline-graphic current" },
              [
                _vm.showPreviousStageline
                  ? [
                      _vm.previousStageline.stagelineType !== "business_info"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "stageline-graphic previous stageline-controls",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "vstack",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToStageline("business_info")
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "indicator-flex" }),
                                  _c(
                                    "div",
                                    { staticClass: "indicator" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "title secondary" },
                                        [
                                          _vm._v(
                                            "\n                Update Business Info\n              "
                                          ),
                                        ]
                                      ),
                                      _vm.stagelineComplete("business_info")
                                        ? _c("stage-complete-icon")
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "indicator-flex" }),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "ellipse-icon" },
                                [_c("ellipse-icon")],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "stageline-graphic previous stageline-controls",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "vstack",
                              on: {
                                click: function ($event) {
                                  return _vm.goToStageline(
                                    _vm.previousStageline.stagelineType
                                  )
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "indicator-flex" }),
                              _c(
                                "div",
                                { staticClass: "indicator" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "title secondary" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.previousStageline.title) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm.stagelineComplete(
                                    _vm.previousStageline.stagelineType
                                  )
                                    ? _c("stage-complete-icon")
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "indicator-flex" }),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "ellipse-icon" },
                            [_c("ellipse-icon")],
                            1
                          ),
                        ]
                      ),
                    ]
                  : _vm._e(),
                _vm.previousPageAvailable
                  ? _c(
                      "div",
                      {
                        staticClass: "nav-arrow-container",
                        on: { click: _vm.goPageBack },
                      },
                      [_c("nav-arrow", { staticClass: "nav-arrow left" })],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.stageList, function (stage, i) {
                  return !_vm.dummy && _vm.currentStage
                    ? _c(
                        "div",
                        {
                          key: stage.id,
                          class: `stage ${
                            stage.id === _vm.currentStage.id ? "active" : ""
                          } ${
                            i === _vm.stageList.length - 1 ? "last-stage" : ""
                          } ${
                            _vm.completed(stage) &&
                            stage.id !== _vm.currentStage.id
                              ? "complete"
                              : ""
                          }`,
                          on: {
                            click: function ($event) {
                              return _vm.clicked(stage)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: `indicator ${
                                _vm.completed(stage) &&
                                stage.id !== _vm.currentStage.id
                                  ? "active"
                                  : ""
                              }`,
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: `title ${
                                    _vm.completed(stage) ||
                                    stage.id === _vm.currentStage.id
                                      ? "complete-title"
                                      : ""
                                  }`,
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(stage.title) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                              stage.id === _vm.currentStage.id
                                ? _c("stage-current-icon", {
                                    staticClass: "current-indicator",
                                  })
                                : _vm._e(),
                              _vm.completed(stage) &&
                              stage.id !== _vm.currentStage.id
                                ? _c("stage-complete-icon")
                                : _vm._e(),
                            ],
                            1
                          ),
                          i !== _vm.stageList.length - 1
                            ? _c("div", { staticClass: "progress-line" }, [
                                _c("hr"),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e()
                }),
                _vm.dummy
                  ? _c("div", { staticClass: "stage active last-stage" }, [
                      _c(
                        "div",
                        { staticClass: "indicator active" },
                        [_vm._m(0), _c("stage-current-icon")],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.nextPageAvailable
                  ? _c(
                      "div",
                      {
                        staticClass: "nav-arrow-container",
                        on: { click: _vm.goPageForward },
                      },
                      [_c("nav-arrow", { staticClass: "nav-arrow right" })],
                      1
                    )
                  : _vm._e(),
                !_vm.dummy && _vm.currentStage && _vm.showNextStageline
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "stageline-graphic next stageline-controls",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ellipse-icon" },
                          [_c("ellipse-icon")],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "vstack",
                            on: { click: _vm.goToNextStageline },
                          },
                          [
                            _c("div", { staticClass: "indicator-flex" }),
                            _c(
                              "div",
                              { staticClass: "indicator" },
                              [
                                _c("div", { staticClass: "title secondary" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.nextStageline.title) +
                                      "\n            "
                                  ),
                                ]),
                                _vm.stagelineComplete(
                                  _vm.nextStageline.stagelineType
                                )
                                  ? _c("stage-complete-icon")
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "indicator-flex" }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.dummy
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "stageline-graphic next stageline-controls",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "ellipse-icon" },
                          [_c("ellipse-icon")],
                          1
                        ),
                        _vm._m(1),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title complete-title" }, [
      _c("div", [_vm._v("What's Next?")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "vstack" }, [
      _c("div", { staticClass: "indicator-flex" }),
      _c("div", { staticClass: "indicator" }, [
        _c("div", { staticClass: "title secondary" }),
        _c("div", { staticClass: "dummy-text" }, [
          _vm._v("\n              ?\n            "),
        ]),
      ]),
      _c("div", { staticClass: "indicator-flex" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }